<template>
  <b-row
    no-gutters
    class="py-2"
  >
    <b-col
      v-for="product in products"
      :key="product.id"
      xs="12"
      sm="6"
      lg="4"
      class="mb-3"
    >
      <ProductCard
        class="px-2 py-1"
        :product="product"
      />
    </b-col>
    <b-col v-if="totalItems > perPage" cols="12">
      <b-pagination
        v-model="page"
        :total-rows="totalItems"
        :per-page="perPage"
        first-number
        last-number
        align="center"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        @change="handleChangePage"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProductCard from '@/components/products/card/ProductCard.vue'

export default {
  name: 'ProductGrid',
  components: { ProductCard },
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      page: 1,
      perPage: 6,
      parameters: {},
    }
  },
  computed: {
    ...mapGetters('products', {
      products: 'getItems',
      totalItems: 'getTotalItems',
    }),
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.reloadData()
      },
    },
  },
  mounted() {
    this.chargeData()
  },
  methods: {
    ...mapActions('products', ['getListProducts']),
    reloadData() {
      this.page = 1
      this.chargeData()
    },
    chargeData() {
      this.getListProducts({ page: this.page, per_page: this.perPage, ...this.filters })
    },
    handleChangePage(page) {
      this.page = page
      this.chargeData()
    },
  },
}
</script>

<style scoped>
</style>
