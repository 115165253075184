<template>
  <b-card
    no-body
    class="cursor-pointer m-0 fill-height"
    @click="$router.push({ name: 'viewProduct', params: { id: product.id } })"
  >
    <b-row
      class="flex-column fill-height"
      align-h="between"
    >
      <b-col cols="auto">
        <img
          v-if="product.image && product.image.length > 0"
          class="product-image"
          :alt="product.name"
          :src="product.image[0].path"
          @error="$event.target.src = defaultImage"
        >
        <img
          v-else
          :alt="product.name"
          :src="defaultImage"
          class="product-image"
        >
        <div class="font-weight-bold font-medium-2 text-dark pt-1">
          {{ product.name }}
        </div>
        <div>
          {{ product.modelo }}
        </div>
      </b-col>
      <b-col
        cols="auto"
        class="mt-1"
      >
        <b-link
          class="text-uppercase"
          :to="{ name: 'viewProduct', params: { id: product.id } }"
        >
          <u>{{ $t('Detalles del producto') }}</u>
        </b-link>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import DefaultImage from '@/assets/images/pages/default-placeholder.png'

export default {
  name: 'ProductCard',
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      defaultImage: DefaultImage,
    }
  },
}
</script>

<style scoped>
.product-image {
  height: 250px;
  width: 100%;
  object-fit: cover;
}
</style>
