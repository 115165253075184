<template>
  <div>
    <div v-if="editActionVisible">
      <b-table-simple hover responsive>
        <thead role="rowgroup" class="">
          <tr role="row" class="">
            <th role="columnheader" scope="col" aria-colindex="1" class="">
              <div>{{ $t('Nombre') }}</div>
            </th>
            <th role="columnheader" scope="col" aria-colindex="2" class="">
              <div>{{ $t('Categoria') }}</div>
            </th>
            <th role="columnheader" scope="col" aria-colindex="3" class="">
              <div>{{ $t('Modelo') }}</div>
            </th>
            <th role="columnheader" scope="col" aria-colindex="4" class="column-actions">
              <div>{{ $t('Acciones') }}</div>
            </th>
          </tr>
        </thead>
        <draggable :list="items" :group="{ name: 'products', pull: 'clone', put: false }" tag="tbody"
          @change="handleChangeOrder">
          <b-tr :key="index" v-for="(item, index) in items">
            <b-td>
              <b-link :to="{ name: 'viewProduct', params: { id: item.id } }" class="link">
                {{ item.name }}
              </b-link>
            </b-td>
            <b-td>
              {{ item.category.name }}
            </b-td>
            <b-td>
              {{ item.modelo }}
            </b-td>
            <b-td class="column-actions">
              <span>
                <b-link :to="{ name: 'viewProduct', params: { id: item.id } }" class="d-inline-block px-50 text-dark">
                  <feather-icon icon="EyeIcon" />
                </b-link>
                <b-link :to="{ name: 'editProduct', params: { id: item.id } }" class="d-inline-block px-50 text-dark">
                  <feather-icon icon="Edit2Icon" />
                </b-link>
                <span class="d-inline-block px-50 text-danger" @click="deleteProduct(item.id, item.name)">
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </b-td>
          </b-tr>
        </draggable>
      </b-table-simple>
    </div>

    <div v-else>
      <b-table hover responsive :items="items" :fields="columns" :no-local-sorting="true"
        @sort-changed="handleSortChange">
        <template #cell(name)="data">
          <b-link :to="{ name: 'viewProduct', params: { id: data.item.id } }" class="link">
            {{ data.item.name }}
          </b-link>
        </template>
        <template #cell(category.name)="data">
          <b-link :to="{ name: 'viewProduct', params: { id: data.item.id } }" class="link">
            {{ data.item.category.name }}
          </b-link>
        </template>
        <template #cell(modelo)="data">
          <b-link :to="{ name: 'viewProduct', params: { id: data.item.id } }" class="link">
            {{ data.item.modelo }}
          </b-link>
        </template>
        <template #cell(actions)="data">
          <span class="column-action">
            <b-link :to="{ name: 'viewProduct', params: { id: data.item.id } }" class="d-inline-block px-50 text-dark">
              <feather-icon icon="EyeIcon" />
            </b-link>
          </span>
        </template>
      </b-table>
    </div>
    <!-- paginador -->
    <div v-if="totalItems > pageLength" class="d-flex align-items-center justify-content-between mb-1 px-2">
      <div class="d-flex align-items-center mb-0">
        <span class="text-nowrap"> {{ $t("PorPagina") }}: </span>
        <b-form-select v-model="pageLength" :options="pages" class="ml-50 mr-1" @input="reloadData" />
        <span class="text-nowrap"> {{ $t("Total") }}: {{ totalItems }}</span>
      </div>
      <div>
        <b-pagination v-model="currentPage" :total-rows="totalItems" :per-page="pageLength" first-number last-number
          align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @change="handleChangePage">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import productsApi from '@/api/products-api'

export default {
  components: {
    draggable
  },
  name: 'ProductsTable',
  props: {
    filters: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      orderBy: null,
      currentPage: 1,
      pageLength: 10,
      pages: ['10', '15', '25'],
      parameters: {},
      columns: [
        {
          label: this.$t('Nombre'),
          key: 'name',
          sortable: true,
        },
        {
          label: this.$t('Categoria'),
          key: 'category.name',
          sortable: true,
        },
        {
          label: this.$t('Modelo'),
          key: 'modelo',
        },
        {
          label: this.$t('Acciones'),
          key: 'actions',
          class: 'column-actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'products/getItems',
      totalItems: 'products/getTotalItems',
      currentUser: 'auth/getUser',
    }),
    editActionVisible() {
      return this.currentUser.roles[0].name === 'super_admin'
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.reloadData()
      },
    },
  },
  mounted() {
    this.chargeData()
  },
  methods: {
    ...mapActions({
      list: 'products/getListProducts',
      reorder: 'products/setOrder',
      delete: "products/delete",
    }),
    reloadData() {
      this.currentPage = 1
      this.chargeData()
    },
    chargeData() {
      this.list({
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        orderBy: this.orderBy,
        ...this.filters,
      })
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handleSortChange(sortParameters) {
      this.orderBy = {
        column: sortParameters.sortBy,
        type: sortParameters.sortDesc ? 'desc' : 'asc',
      }
      this.reloadData()
    },
    async handleChangeOrder(evt) {
      console.log('se ha cambiado', evt)
      const productsSetAux = []

      this.items.forEach((element, index) => {
        productsSetAux[index] = {
          order: index + 1,
          id: element.id,
          type: element.type,
          name: element.name,
        }
      })

      //send items to server
      // console.log('nuevos items', productsSetAux);
      // console.log('subiendo items', productsSetAux.map(item => item.id))

      this.reorder(productsSetAux.map(item => item.id));

    },
    deleteProduct(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: "bodyDelete",
          footerClass: "footerDelete",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-danger",
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.delete(id);
          }
        });
    }
  },
}
</script>

<style scoped></style>
