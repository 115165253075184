<template>
  <div>
    <div v-if="$route.params.category_id" class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder h2back m-0">
        <span
            @click="$router.go(-1)"
        >
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span>
        <span v-if="listPrepare && parent.parent"> {{ parent.parent.name[currentLanguage] }} / {{ parent.name }}</span>
      </h2>
    </div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-row
            align-h="between"
            align-v="center"
            class="px-1 pt-2 pb-1"
          >
            <b-col cols="auto">
              <h3 class="m-0">
                {{ $t('Productos')  }}
              </h3>
            </b-col>
            <b-col cols="auto">
              <template v-if="createActionVisible">
                <b-link :to="{ name: 'createProduct' }">
                  <b-button
                    variant="primary"
                    class="mr-1 text-nowrap"
                  >
                    {{ '+ ' + $t('Añadir nuevo producto') }}
                  </b-button>
                </b-link>
              </template>
            </b-col>
          </b-row>
          <hr>
          <b-row
            align-h="between"
            align-v="center"
            no-gutters
            class="px-2 pt-1 pb-2"
          >
            <b-col cols="auto">
              <b-button-group>
                <b-button
                  class="p-50"
                  @click="layout = 'product-grid'"
                >
                  <feather-icon
                    icon="GridIcon"
                    size="22"
                  />
                </b-button>
                <b-button
                  class="p-50 btn-danger"
                  @click="layout = 'product-table'"
                >
                  <feather-icon
                    icon="ListIcon"
                    size="22"
                  />
                </b-button>
              </b-button-group>
            </b-col>
            <b-col cols="12" lg="5" md="8">
              <b-row align-h="end">
                <b-col cols="12" md="8" lg="10" class="pt-1 py-md-0">
                  <div class="d-flex justify-content-end align-items-center">
                    <b-form-input
                      :placeholder="$t('Buscar por nombre')"
                      type="text"
                      class="d-inline-block w-75"
                      v-model="searchTermInit"
                      @keyup.enter="searchTermFind"
                    />
                    <b-button
                      class="ml-1"
                      variant="primary"
                      @click="searchTermFind"
                    >
                      {{ $t("Buscar") }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <component
            :is="layout"
            :filters="filters"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ProductsTable from '@/components/products/table/ProductsTable.vue'
import ProductGrid from '@/components/products/grid/ProductGrid.vue'

export default {
  components: {
    'product-grid': ProductGrid,
    'product-table': ProductsTable,
  },
  data() {
    return {
      category: null,
      searchTerm: null,
      searchTermInit: null,
      layout: 'product-grid',
      listPrepare: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getUser',
      parent: 'categories/getCategory',
      currentLanguage: 'languages/getCurrentLanguage',
    }),
    filters() {
      return {
        category: this.$route.params?.category_id || null,
        search: this.searchTerm,
      }
    },
    createActionVisible() {
      return !['user', 'documental'].includes(this.currentUser.roles[0].name)
    },
  },
  created() {
    this.chargeData()
  },
  methods: {
    ...mapActions({
      getCategory: 'categories/getCategory',
    }),
    chargeData() {
      if (this.$route.params.category_id) {
        this.getCategory(this.$route.params.category_id)
      }
      this.listPrepare = true
    },
    searchTermFind() {
      this.searchTerm = this.searchTermInit
    },
  },
}
</script>
<style scoped>
</style>
